import $ from 'jquery';
import {togglePushy, closePushy, openPushyFallback, toggleSubmenu, closeOnKeyUp} from "../vendor/pushy.js";
import "../vendor/doubleTapToGo.js";

export default function () {
  var init = function() {
    // Initialize double tap
    $('.js-nav-primary').doubleTapToGo();

    // Handle hamburger clicks
    let hamburgers = document.querySelectorAll('.js-btn-hamburger');
    hamburgers.forEach((burger) => {
      burger.setAttribute('aria-expanded', 'false');

      burger.addEventListener('click', (e) => {
        e.preventDefault();

        // Toggle aria-expanded state
        const isExpanded = burger.getAttribute('aria-expanded') === 'true';
        burger.setAttribute('aria-expanded', !isExpanded);

        // Call original Pushy toggle
        togglePushy(e);
      });
    });

    // Handle overlay clicks to reset aria-expanded
    const overlay = document.querySelector('.site-overlay');
    overlay.addEventListener('click', () => {
      hamburgers.forEach(burger => burger.setAttribute('aria-expanded', 'false'));
    });
  }

  init();
}
